@font-face {
  font-family: 'incicarto';
  src: url('../fonts/incicarto.eot?33558329');
  src: url('../fonts/incicarto.eot?33558329#iefix') format('embedded-opentype'),
       url('../fonts/incicarto.woff2?33558329') format('woff2'),
       url('../fonts/incicarto.woff?33558329') format('woff'),
       url('../fonts/incicarto.ttf?33558329') format('truetype'),
       url('../fonts/incicarto.svg?33558329#incicarto') format('svg');
  font-weight: normal;
  font-style: normal;
}

div.ol-overlaycontainer-stopevent > div > div.m-area > div.m-panel.m-incicarto.collapsed > button:before,
.m-incicarto-addwfs [class^="icon-"],
.m-incicarto-addwfs [class*=" icon-"],
.m-incicarto-loading [class^="icon-"],
.m-incicarto-loading [class*=" icon-"],
div.m-incicarto [class^="icon-"],
div.m-incicarto [class*=" icon-"] {
  font-family: "incicarto";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.m-incicarto .icon-incicarto:before { content: '\e81b'; } /* Icono del plugin */
.m-incicarto .icon-visible:before { content: '\e801'; }
.m-incicarto .icon-invisible:before { content: '\e802'; }
.m-incicarto .icon-points:before { content: '\e80e'; }
.m-incicarto .icon-download:before { content: '\e90e'; }
.m-incicarto .icon-notify:before { content: '\e81c'; }
.m-incicarto .icon-edit:before { content: '\e910'; }
.m-incicarto .icon-show:before { content: '\e916'; }
.m-incicarto .icon-hide:before { content: '\e918'; }
.m-incicarto .icon-right-arrow:before { content: '\e919'; }
.m-incicarto .icon-left-arrow:before { content: '\e91d'; }
.m-incicarto .icon-point:before { content: '\e924'; }
.m-incicarto .icon-lines:before { content: '\e92b'; }
.m-incicarto .icon-zoom:before { content: '\e92f'; }
.m-incicarto .icon-add:before { content: '\e935'; }
.m-incicarto .icon-delete:before { content: '\e93e'; }
.m-incicarto .icon-polygon:before { content: '\e947'; }
.m-incicarto .icon-upload:before { content: '\e95e'; }
.m-incicarto .icon-cancel:before { content: '\e905'; }
.m-incicarto .icon-check:before { content: '\e909'; }
.m-incicarto .icon-checked:before { content: '\e90a'; }
.m-incicarto .icon-filters:before { content: '\e93d'; }
.m-incicarto .icon-search:before { content: '\e964'; }
.m-incicarto .icon-spinner:before { content: '\e953'; }
.m-incicarto .icon-list:before { content: '\e92c'; }
.m-plugin-incicarto-form .icon-upload:before { content: '\e95e'; }

#m-incicarto-select-wfs .icon-show:before { content: '\e916'; }
#m-incicarto-select-wfs .icon-hide:before { content: '\e918'; }
.m-incicarto-addwfs .icon-search:before { content: '\e964'; }
.m-incicarto-addwfs .icon-list:before { content: '\e92c'; }
.m-incicarto-addwfs .icon-cancel:before { content: '\e905'; }
.m-incicarto-loading .icon-spinner:before { content: '\e953'; }
